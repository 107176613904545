'use client';

import { ActionIcon, Anchor, Box, Container, Divider, Group, Text, rem } from '@mantine/core';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBrandYoutube,
} from '@tabler/icons-react';
import LogoGroup from '../LogoGroup';
import classes from './Footer.module.css';

const pages = [
  {
    title: 'About',
    links: [
      { label: 'FAQs', link: '/FAQs' },
      { label: 'Glossary', link: '/glossary' },
      { label: 'Blog', link: '/blog' },
    ],
  },
  {
    title: 'Policies',
    links: [
      { label: 'Terms of Service', link: '/terms-of-service' },
      { label: 'Privacy Policy', link: '/privacy-policy' },
      { label: 'Cookie Policy', link: '/cookie-policy' },
    ],
  },
  {
    title: 'Community',
    links: [
      { label: 'Contact Us', link: '/contact' },
      { label: 'Email newsletter', link: '#newsletter' },
      { label: 'Join our Discord', link: '#' },
    ],
  },
];

const socials = [
  {
    label: 'Twitter',
    link: '#',
    icon: IconBrandTwitter,
  },
  {
    label: 'YouTube',
    link: '#',
    icon: IconBrandYoutube,
  },
  {
    label: 'Instagram',
    link: '#',
    icon: IconBrandInstagram,
  },
  {
    label: 'Facebook',
    link: '#',
    icon: IconBrandFacebook,
  },
  {
    label: 'LinkedIn',
    link: '#',
    icon: IconBrandLinkedin,
  },
];

export default function Footer() {
  const groups = pages.map((group) => {
    const links = group.links.map((link, index) => (
      <Anchor key={index} href={link.link} underline="hover" c="inherit" display="block" size="sm">
        {link.label}
      </Anchor>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text fw="bold" fz="lg">
          {group.title}
        </Text>
        {links}
      </div>
    );
  });

  return (
    <Box component="footer" className={classes.footer}>
      <Container>
        <Group justify="space-between">
          <Box className={classes.logo}>
            <LogoGroup />
            <Text size="xs" mt="sm" c="dimmed" className={classes.description}>
              The best software for small businesses to manage their inventory.
            </Text>
          </Box>
          <Box className={classes.groups}>{groups}</Box>
        </Group>
        <Divider my="xl" />
        <Box className={classes.afterFooter}>
          <Text c="dimmed" size="sm">
            © {String(new Date().getFullYear())} Invie. All rights reserved.
          </Text>
          <Group gap={0} className={classes.social} justify="flex-end" wrap="nowrap">
            {socials.map((social, index) => (
              <ActionIcon
                key={index}
                component="a"
                aria-label={social.label}
                href={social.link}
                variant="subtle"
                radius="xl"
                size="lg"
                target="_blank"
              >
                <social.icon style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
              </ActionIcon>
            ))}
          </Group>
        </Box>
      </Container>
    </Box>
  );
}
