import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function LogoIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 466 512"
      fill="var(--mantine-color-indigo-text)"
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.284 0.0397987C219.028 0.43227 207.04 3.72098 196.302 9.6363L34.3908 105.025C13.281 118.456 0 142.245 0 167.989V337.561L0.163099 342.38C0.98974 354.994 4.99181 367.196 11.7981 377.853C18.6044 388.51 27.9945 397.277 39.0974 403.342L195.091 501.966L199.658 504.294C222.608 515.164 248.261 514.442 269.651 502.711L428.161 402.574L432.122 400.246C452.719 387.118 466 363.306 466 337.561V168.012L465.883 163.264L465.464 158.562C464.235 148.525 460.989 138.84 455.92 130.088C450.851 121.336 444.064 113.698 435.966 107.632L433.473 105.886L431.329 104.094L429.093 102.628L271.235 9.6363L266.808 7.37844C255.714 2.16227 243.539 -0.352673 231.284 0.0397987ZM259.139 176.423C264.816 170.963 267.655 164.317 267.655 156.484C267.655 148.651 264.816 142.005 259.139 136.545C253.698 130.848 247.075 128 239.269 128C231.463 128 224.84 130.848 219.399 136.545C213.959 142.005 211.238 148.651 211.238 156.484C211.238 164.317 213.959 170.963 219.399 176.423C224.84 181.882 231.463 184.612 239.269 184.612C247.075 184.612 253.698 181.882 259.139 176.423ZM177.885 330.949C177.412 334.746 177.176 338.188 177.176 341.274C177.176 354.804 180.487 365.367 187.111 372.962C193.97 380.321 203.314 384 215.141 384C231.7 384 246.366 378.422 259.139 367.266C271.913 356.109 282.084 339.731 289.654 318.131H274.751C271.913 327.388 267.536 334.984 261.623 340.918C255.709 346.615 249.441 349.463 242.817 349.463C237.613 349.463 233.829 348.514 231.463 346.615C229.334 344.478 228.27 341.037 228.27 336.289C228.27 334.39 228.388 332.61 228.625 330.949C228.861 329.05 229.216 327.032 229.689 324.896L255.236 203.839H204.142L180.014 318.131C179.068 322.641 178.358 326.913 177.885 330.949Z"
      />
    </svg>
  );
}
