import { ActionIcon, Anchor, Group, VisuallyHidden } from '@mantine/core';
import Link from 'next/link';
import { Logo, LogoIcon } from '../icons';

export default function LogoGroup() {
  return (
    <Group gap="xs" align="center">
      <ActionIcon href="/" component={Link} radius="xl" size="lg" variant="subtle">
        <LogoIcon size={24} />
        <VisuallyHidden>Invie Logo</VisuallyHidden>
      </ActionIcon>
      <Anchor href="/" underline="never">
        <Logo height={24} style={{ display: 'block', marginBottom: '0.15rem' }} />
        <VisuallyHidden>Invie</VisuallyHidden>
      </Anchor>
    </Group>
  );
}
