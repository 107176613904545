import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function FacebookIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      viewBox="0 0 30 30"
      width="1rem"
      height="1rem"
      fill="white"
      preserveAspectRatio="xMidYMid"
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path
        d="M30 15.091C30 6.756 23.285 0 15 0S0 6.756 0 15.091C0 22.625 5.484 28.868 12.656 30V19.454H8.848V15.09h3.808v-3.324c0-3.782 2.239-5.872 5.666-5.872 1.64 0 3.358.295 3.358.295v3.714h-1.893c-1.863 0-2.443 1.164-2.443 2.358v2.83h4.16l-.665 4.362h-3.495V30C24.516 28.868 30 22.625 30 15.091z"
        fill="#1877f2"
      />
    </svg>
  );
}
