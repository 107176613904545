import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function Logo({ size, style, ...others }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 513 197"
      fill="none"
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path
        d="M70.7578 22.2801C70.7578 28.2773 68.5678 33.3658 64.1879 37.5457C59.9904 41.7256 54.8804 43.8155 48.858 43.8155C42.8355 43.8155 37.7256 41.7256 33.5281 37.5457C29.3307 33.3658 27.2319 28.2773 27.2319 22.2801C27.2319 16.2828 29.3307 11.1943 33.5281 7.01442C37.7256 2.6528 42.8355 0.471985 48.858 0.471985C54.8804 0.471985 59.9904 2.6528 64.1879 7.01442C68.5678 11.1943 70.7578 16.2828 70.7578 22.2801ZM0.952148 163.76C0.952148 161.397 1.13465 158.762 1.49964 155.854C1.86464 152.765 2.41214 149.494 3.14213 146.041L21.757 58.536H61.1766L41.4668 151.22C41.1018 152.856 40.8281 154.401 40.6456 155.854C40.4631 157.127 40.3718 158.49 40.3718 159.943C40.3718 163.578 41.1931 166.213 42.8355 167.849C44.6605 169.303 47.5805 170.03 51.5955 170.03C56.7054 170.03 61.5416 167.849 66.1041 163.487C70.6665 158.944 74.0428 153.128 76.2327 146.041H87.7301C81.8902 162.579 74.0428 175.118 64.1879 183.66C54.3329 192.201 43.018 196.472 30.2431 196.472C21.1182 196.472 13.9095 193.655 8.61708 188.021C3.50713 182.206 0.952148 174.119 0.952148 163.76Z"
        fill="var(--mantine-color-indigo-text)"
      />
      <path
        d="M140.506 165.668C140.506 162.397 140.871 158.308 141.601 153.401C142.513 148.494 144.247 140.68 146.802 129.957C149.357 119.599 150.999 112.147 151.729 107.604C152.459 103.061 152.824 99.2444 152.824 96.1549C152.824 91.6115 152.003 88.1586 150.361 85.7961C148.718 83.4335 146.254 82.2522 142.969 82.2522C138.589 82.2522 134.483 84.5239 130.651 89.0673C127.001 93.4289 123.99 99.517 121.617 107.332L103.002 194.836H63.5826L92.5999 58.536H132.02L129.008 72.7112C133.571 67.6227 138.498 63.8063 143.791 61.262C149.083 58.7177 154.832 57.4456 161.037 57.4456C170.709 57.4456 178.192 60.0807 183.484 65.351C188.777 70.6213 191.423 78.2541 191.423 88.2495C191.423 92.4293 190.875 97.427 189.78 103.243C188.868 108.876 186.952 117.599 184.032 129.412C181.659 138.681 180.108 145.223 179.378 149.039C178.648 152.856 178.283 156.036 178.283 158.58C178.283 162.397 179.195 165.305 181.02 167.304C182.845 169.121 185.583 170.03 189.233 170.03C193.795 170.03 197.719 168.485 201.004 165.396C204.289 162.306 208.03 155.854 212.228 146.041H223.725C217.885 162.942 210.768 175.573 202.373 183.932C194.16 192.292 184.305 196.472 172.808 196.472C162.588 196.472 154.649 193.746 148.992 188.294C143.334 182.66 140.506 175.118 140.506 165.668Z"
        fill="var(--mantine-color-indigo-text)"
      />
      <path
        d="M297.057 81.707C294.137 81.707 291.765 80.344 289.94 77.618C288.115 74.7103 287.202 71.4391 287.202 67.8044C287.202 63.2611 288.662 59.7172 291.582 57.173C294.685 54.447 298.882 53.084 304.175 53.084C309.832 53.084 314.121 55.1739 317.041 59.3538C319.961 63.5337 321.421 69.3491 321.421 76.8002C321.421 89.1581 319.778 102.152 316.493 115.782C313.391 129.23 309.102 141.498 303.627 152.583C296.327 166.94 287.567 177.844 277.347 185.295C267.128 192.746 255.813 196.472 243.403 196.472C232.635 196.472 224.332 193.746 218.492 188.294C212.652 182.66 209.732 174.664 209.732 164.305C209.732 162.306 209.914 160.216 210.279 158.035C210.462 155.673 210.736 153.401 211.1 151.22L230.81 58.536H270.23L250.52 151.22C250.155 153.219 249.79 155.128 249.425 156.945C249.243 158.58 249.151 159.943 249.151 161.034C249.151 163.942 249.881 166.213 251.341 167.849C252.984 169.303 255.356 170.03 258.459 170.03C264.846 170.03 271.051 166.759 277.074 160.216C283.279 153.492 288.845 144.042 293.772 131.866C297.422 123.142 300.342 113.965 302.532 104.333C304.722 94.701 305.817 86.2504 305.817 78.981C305.452 79.708 304.266 80.344 302.258 80.8892C300.433 81.4344 298.7 81.707 297.057 81.707Z"
        fill="var(--mantine-color-indigo-text)"
      />
      <path
        d="M393.16 22.2801C393.16 28.2773 390.97 33.3658 386.59 37.5457C382.392 41.7256 377.282 43.8155 371.26 43.8155C365.238 43.8155 360.128 41.7256 355.93 37.5457C351.733 33.3658 349.634 28.2773 349.634 22.2801C349.634 16.2828 351.733 11.1943 355.93 7.01442C360.128 2.6528 365.238 0.471985 371.26 0.471985C377.282 0.471985 382.392 2.6528 386.59 7.01442C390.97 11.1943 393.16 16.2828 393.16 22.2801ZM323.354 163.76C323.354 161.397 323.537 158.762 323.902 155.854C324.267 152.765 324.814 149.494 325.544 146.041L344.159 58.536H383.579L363.869 151.22C363.504 152.856 363.23 154.401 363.048 155.854C362.865 157.127 362.774 158.49 362.774 159.943C362.774 163.578 363.595 166.213 365.238 167.849C367.062 169.303 369.982 170.03 373.997 170.03C379.107 170.03 383.944 167.849 388.506 163.487C393.069 158.944 396.445 153.128 398.635 146.041H410.132C404.292 162.579 396.445 175.118 386.59 183.66C376.735 192.201 365.42 196.472 352.645 196.472C343.52 196.472 336.312 193.655 331.019 188.021C325.909 182.206 323.354 174.119 323.354 163.76Z"
        fill="var(--mantine-color-indigo-text)"
      />
      <path
        d="M498.991 82.2522C498.991 97.3362 492.786 110.512 480.376 121.779C467.966 132.865 453.184 138.862 436.029 139.771C435.847 142.86 435.664 145.223 435.482 146.859C435.482 148.313 435.482 149.585 435.482 150.675C435.482 159.035 436.85 164.941 439.588 168.394C442.508 171.847 447.709 173.574 455.191 173.574C463.769 173.574 471.616 171.665 478.734 167.849C486.034 163.851 494.337 156.581 503.645 146.041H512.952C502.367 163.124 490.87 175.845 478.46 184.205C466.05 192.383 452.454 196.472 437.672 196.472C423.619 196.472 412.943 192.837 405.643 185.568C398.526 178.299 394.967 167.485 394.967 153.128C394.967 142.77 396.609 131.775 399.894 120.144C403.179 108.513 407.559 98.154 413.034 89.0673C419.787 78.1632 427.817 69.9852 437.124 64.5332C446.614 58.8994 457.199 56.0826 468.879 56.0826C478.916 56.0826 486.399 58.2634 491.326 62.625C496.436 66.8049 498.991 73.3473 498.991 82.2522ZM471.069 70.5304C464.864 70.5304 458.568 76.0733 452.18 87.1591C445.975 98.0631 441.322 111.239 438.219 126.686C448.804 125.959 458.02 121.143 465.868 112.238C473.897 103.152 477.912 92.9745 477.912 81.707C477.912 77.8906 477.365 75.0738 476.27 73.2564C475.175 71.4391 473.441 70.5304 471.069 70.5304Z"
        fill="var(--mantine-color-indigo-text)"
      />
    </svg>
  );
}
